import { render, staticRenderFns } from "./famille.vue?vue&type=template&id=33979e4e"
import script from "./famille.vue?vue&type=script&setup=true&lang=ts"
export * from "./famille.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./famille.vue?vue&type=style&index=0&id=33979e4e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesLoader: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/loader/loader.vue').default,AtomsInputRadioCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-radio-card/input-radio-card.vue').default,FoundationsUiIcon: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-icon/ui-icon.vue').default,Step: require('/vercel/path0/components/Step.vue').default,OrganismsHighlightV2: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/highlight-v2/highlight-v2.vue').default,AdvantagesPush: require('/vercel/path0/components/AdvantagesPush.vue').default,OrganismsRatingsPush: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/ratings-push/ratings-push.vue').default,FoundationsUiWrapper: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-wrapper/ui-wrapper.vue').default,OrganismsContactPush: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/contact-push/contact-push.vue').default})
